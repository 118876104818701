<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import Popover from "./Popover.svelte";

  export let itemName;
  export let itemDescription;
  export let itemSpan;
  export let itemStart;
  export let startDate;
  export let endDate;
  export let overflowLeft = false;
  export let overflowRight = false;
  export let itemUrl;
  export let itemUid;
  let breakOutRight;
  let breakOutLeft;
  let popoverState = writable({
    show: false,
    el: null,
  });

  setContext("popoverState", popoverState);

  function togglePopover() {
    $popoverState.show = !$popoverState.show;
    $popoverState.el = this;
    // console.log("toggle popoverState.show: ", $popoverState.show)
  }

</script>

<div
  class="GanttItem"
  class:OverflowRight={overflowRight}
  class:OverflowLeft={overflowLeft}
  style="grid-column-start: {itemStart}; grid-column-end: span {itemSpan};"
  title="{itemName} ({startDate} - {endDate})"
  on:click={togglePopover}
>
  <div class="itemInnerWrapper">
    <Popover id={itemUid}>
      <div slot="target">
        <div class="summary">{itemName}</div>
      </div>
      <div slot="content">
        <div class="popoverContent">
          <p><i>{startDate} - {endDate}:</i></p>
          <p><b>{itemName}</b></p>
          <p>{itemDescription}</p>
          <a href={itemUrl}>Details</a>
        </div>
      </div>
    </Popover>
  </div>
</div>

<style lang="scss">.GanttItem {
  background-color: var(--row-item-color-bg);
  border-radius: 5px;
  color: var(--row-item-color-font);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 5px;
  min-height: 1em;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemInnerWrapper {
  padding: 0.4em;
}

.summary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GanttItem > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OverflowRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.OverflowLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* .PopoverContent {
  display: inline-block;
}
details[data-popover] {
  overflow: hidden;
  width: 100%;
}
details[data-popover] > summary:focus {
  outline: none;
}
details[data-popover] > summary::-webkit-details-marker {
  display: none;
}
details[data-popover] > summary {
  list-style: none;
  text-decoration: underline dotted teal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
details[data-popover] > summary + * {
  position: absolute;
  display: block;
  white-space: normal;
  z-index: 1;
  width: 20rem;
  border: solid 1px teal;
  border-radius: 5%;
  padding: 10px;
  background: white;
}
details[data-popover] > * + * {
  display: none;
}

details[data-popover="up"] > summary + * {
  bottom: calc(0.5rem + 100%);
  right: 50%;
  transform: translateX(50%);
}
details[data-popover="down"] > summary + * {
  top: calc(0.5rem + 100%);
  right: 50%;
  transform: translateX(50%);
}
details[data-popover="left"] > summary + * {
  right: calc(1rem + 100%);
  bottom: 50%;
  transform: translateY(50%);
}
details[data-popover="right"] > summary + * {
  left: calc(1rem + 100%);
  bottom: 50%;
  transform: translateY(50%);
} */</style>
